import React, { memo } from "react";
import { observer } from "mobx-react-lite";
import {Box, Typography, TextField, Button, styled } from '@mui/material';
import { profileStore } from "../State/ProfileStore";

const CustomTypography = styled(Typography)(({ theme }) => ({
    fontFamily: 'Montserrat, sans-serif',
  }));

const StyledTextField = styled(TextField)(({ theme }) => ({
    '& .MuiInputBase-input': {
      fontFamily: 'Montserrat, sans-serif',
      color: '#23374C', 
    },
    '& .MuiInputLabel-root': {
      fontFamily: 'Montserrat, sans-serif',
      color: '#23374C', 
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#23374C', 
      },
      '&:hover fieldset': {
        borderColor: '#1A5F7A', 
      },
      '&.Mui-focused fieldset': {
        borderColor: '#1A5F7A', 
      },
    },
  }));
  
  const MemoizedTextField = memo(({ ...props }) => (
    <StyledTextField {...props} />
  ));
  
  const CustomButton = styled(Button)(({ theme }) => ({
    fontFamily: 'Montserrat, sans-serif',
    marginTop: theme.spacing(3),
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    backgroundColor: '#1A5F7A',
    '&:hover': {
      backgroundColor: '#23374C',
    },
  }));

const PasswordResetForm = observer(() => (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth: '800px',
        width: '100%',
        margin: '0 auto',
        textAlign: 'center',
        padding: '2rem',
        position: 'relative',
      }}
    >
      <CustomTypography variant="h4" gutterBottom sx={{ mb: 5 }}>
        Password Reset
      </CustomTypography>
      <CustomTypography variant="body1" paragraph sx={{ mb: 1, width: "60%" }}>
        Click the button below to change or update your password. 
        You will receive an email with a link to reset your password shortly if the provided email is associated with an account.
      </CustomTypography>
      <Box
        component="form"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '150%',
          maxWidth: '400px',
          '& > :not(style)': { m: 1, width: '100%' },
        }}
        noValidate
        autoComplete="off"
      >
        <MemoizedTextField
          required
          label="Email Address"
          value={profileStore.userEmail}
          onChange={profileStore.handleUserEmailChange}
          error={profileStore.isUserEmailValid}
          helperText={profileStore.isUserEmailValid ? 'Please enter a valid email address' : ''}
        />
        <CustomButton variant="contained" onClick={profileStore.sendEmail}>
          Send Password Reset Email
        </CustomButton>
        {profileStore.sendEmailStatus && (
          <Typography variant="body2" sx={{ mt: 2 }}>
            {profileStore.sendEmailStatus}
          </Typography>
        )}
      </Box>
    </Box>
  ));

export default PasswordResetForm; 