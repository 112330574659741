import React, { useEffect, useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { CardActionArea, Box, Typography, CardMedia, CardContent, Card, styled, CircularProgress } from '@mui/material';
import CancelSubscription from './CancelSubscription';
import BasePlan from '../Logos/Goalunit Logo - Navy Horisontal.svg';

const CustomTypography = styled(Typography)(({ theme }) => ({
    fontFamily: 'Montserrat, sans-serif',
}));

const SubscriptionPlans = () => {
    const [ plans, setPlans ] = useState([]);
    const [ activeSubscription, setActiveSubscription ] = useState([]);
    const { getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        const fetchSubscriptionplans = async () => {
            try {
                const REACT_APP_SERVER = process.env.REACT_APP_SERVER;
                const token = await getAccessTokenSilently();
                const header = { Authorization: `Bearer ${token}` };
                const subscriptionplans = await fetch(`${REACT_APP_SERVER}/assistant-manager/api/subscriptionplans`, { headers: header, credentials: 'include' })

                if (!subscriptionplans.ok) {
                    throw new Error(`HTTP error! Status: ${subscriptionplans.status}`);
                }

                const plan_response = await subscriptionplans.json();

                const sortedPlans = plan_response.sort((a, b) => {
                    const minPriceA = Math.min(...a.Prices.map(priceObj => priceObj.price));
                    const minPriceB = Math.min(...b.Prices.map(priceObj => priceObj.price));

                    return minPriceA - minPriceB;
                });

                setPlans(sortedPlans)
                setActiveSubscription(sortedPlans.filter(plan => plan.Subscription.id !== ""))
            } catch (error) {
                console.log("Something went wrong", error);
            }

        };
        fetchSubscriptionplans();
    }, []);

    const handleCardClick = async (plan) => {
        if (plan.Subscription.id === "" || plan.Subscription.cancelled_at > 0) {
            try {
                const REACT_APP_SERVER = process.env.REACT_APP_SERVER;
                const token = await getAccessTokenSilently();
                const header = { Authorization: `Bearer ${token}` };
                const body = JSON.stringify({ itemPriceId: plan.Prices[0].id });
                const response = await fetch(`${REACT_APP_SERVER}/assistant-manager/api/upsertsubscription`, {
                    method: 'POST',
                    headers: header,
                    credentials: 'include',
                    body: body
                });
                const hostedpage_response = await response.json();
                localStorage.setItem('shouldStartPolling', 'true');
                window.location.href = hostedpage_response.url;
            } catch (error) {
                console.log("Something went wrong", error);
            }
        }
    }

    return (
        <div>
            {plans.length > 0 ? (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 5, justifyContent: 'center' }}>
                        {plans.map((plan, index) => (
                            <Box key={index} sx={{
                                width: "30%",
                                backgroundColor: "#23374C",
                                borderRadius: 3,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                marginBottom: '2vh',
                                transform: plan.Subscription.id !== "" && plan.Subscription.cancelled_at === 0 ? "scale(1.10)" : "none",
                                transition: "transform 0.3s ease",
                                boxShadow: plan.Subscription.id !== "" && plan.Subscription.cancelled_at === 0
                                    ? "0 10px 30px rgba(0, 0, 0, 0.25)"
                                    : "0 4px 10px rgba(0, 0, 0, 0.1)",
                                border: plan.Subscription.id !== "" && plan.Subscription.cancelled_at === 0 ? "4px solid #FFD700" : "none",
                            }}>
                                <CustomTypography sx={{
                                    fontSize: 18,
                                    color: 'white',
                                    textAlign: "center",
                                    width: "100%",
                                    ml: '20px',
                                    fontWeight: 600
                                }}>
                                    {plan.Subscription.id !== ""
                                        ? (plan.Subscription.cancelled_at > 0
                                            ? `Current (Expires on ${new Date(plan.Subscription.cancelled_at * 1000).toDateString()})`
                                            : "Current")
                                        : "Choose"}

                                </CustomTypography>
                                <Card
                                    sx={{
                                        borderRadius: 3,
                                        width: "98%",
                                        backgroundColor: "#F3F5F1",
                                    }}
                                >
                                    <CardActionArea sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}
                                        onClick={() => handleCardClick(plan)}>
                                        <CardContent
                                            sx={{
                                                width: "100%",
                                                backgroundColor: "#F3F5F1",
                                                textAlign: 'center',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <CardMedia
                                                sx={{
                                                    marginTop: '15px',
                                                    marginBottom: '15px',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    width: "80%"
                                                }}
                                                component="img"
                                                image={BasePlan}
                                                alt="Base-plan"
                                            />
                                        </CardContent>
                                        <CardContent sx={{ width: "100%", textAlign: 'center' }}>
                                            <CustomTypography variant="h4" component="div" fontWeight={450}>
                                                {plan.Plan.external_name}
                                            </CustomTypography>
                                        </CardContent>
                                        <CardContent sx={{ textAlign: 'center' }}>
                                            <CardContent sx={{ width: "90%", display: 'flex', justifyContent: 'center', minHeight: '175px' }}>
                                                <CustomTypography variant="body2" sx={{ fontSize: 16, textAlign: 'left' }}>
                                                    {plan.Prices.map((price, idx) => (
                                                        <CustomTypography variant="body1" padding="5px" fontWeight={650}>
                                                            {price.trial_period_unit === '' ? ' ' : `A ${price.trial_period} ${price.trial_period_unit} free trial`}
                                                        </CustomTypography>
                                                    ))}
                                                    {plan.Plan.description.split(',').map((item, idx) => (
                                                        <CustomTypography key={idx} variant="body2" sx={{ fontSize: 16, mt: 1, textAlign: 'left' }}>
                                                            <li>{item.trim()}</li>
                                                        </CustomTypography>
                                                    ))}
                                                </CustomTypography>
                                            </CardContent>
                                            {plan.Prices.map((price, idx) => (
                                                <CardContent sx={{ width: "90%", display: 'flex', justifyContent: 'center', }} key={idx}>
                                                    <CustomTypography sx={{ fontSize: '2rem' }} >
                                                        {price.currency_code === 'EUR' ? '€' : price.currency_code}{price.price / 100} / {price.period_unit}
                                                    </CustomTypography>
                                                    {idx < plan.Prices.length - 1 && (
                                                        <CustomTypography variant="body1" sx={{ fontSize: 20, mt: 2 }}>
                                                            OR
                                                        </CustomTypography>
                                                    )}
                                                </CardContent>
                                            ))}
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Box>
                        ))}
                    </Box>
                    <CancelSubscription userSubscription={activeSubscription} />
                </div>
            )
                :
                (<CircularProgress />)}
        </div>
    );
};

export default SubscriptionPlans; 
