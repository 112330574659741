import { React, useState, useEffect, useRef } from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import { Chart, registerables } from 'chart.js'
import { exportPdfStore } from '../State/ExportPdfStore';

Chart.register(...registerables);

const PlayerMarketValueAssessment = (props) => {

  const [chartData, setChartData] = useState({
    datasets: []
  });

  const chartRef = useRef(null);

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: 'nearest',
      axis: 'x',
      intersect: false
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Season"
        },
        grid: {
          display: true
        }
      },
      y: {
        title: {
          display: true,
          text: "%"
        },
        beginAtZero: true,
        grid: {
          display: true
        }
      }
    },
    plugins: {
      legend: {
        position: 'top',
        labels: {
          padding: 20,
          font: {
            size: 16 // Adjust this value to change the font size
          }
        }
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += Math.round(context.parsed.y) + '%';
            }
            return label;
          }
        }
      }
    },
    animation: {
      onComplete: function () {
        if (chartRef.current) {
          const base64Image = chartRef.current.toBase64Image();
          exportPdfStore.setPlayerMarketValueAssessmentData(base64Image);
        }
      }
    }
  };


  const loadData = (jsonData) => {
    var data = jsonData.filter(item => item.SeasonName);
    data.sort((a, b) => a.SeasonName.localeCompare(b.SeasonName));

    const seasons = data.map(item => item.SeasonName);
    const growthSum = data.map(item => item.GrowthSum);
    const valueSum = data.map(item => item.ValueSum);
    const senioritySum = data.map(item => item.SenioritySum);
    const oppCostSum = data.map(item => item.OppCostSum);

    // Att dela upp de så här kommer inte säkerställa att rätt värde kommer för rätt säsong. 
    // Det innebär att vi kommer att behöva göra om här lite. 

    const backgroundcolors = [
      getComputedStyle(document.documentElement).getPropertyValue('--element-color-1'),
      getComputedStyle(document.documentElement).getPropertyValue('--element-color-2'),
      getComputedStyle(document.documentElement).getPropertyValue('--element-color-3'),
      getComputedStyle(document.documentElement).getPropertyValue('--element-color-4')
    ].map(color => {
      const rgbaColor = color.replace(')', ', 0.5)').replace('rgb', 'rgba');
      return rgbaColor;
    });

    const datasets = [
      {
        label: 'Growth',
        data: growthSum,
        backgroundColor: backgroundcolors[0],
        borderColor: backgroundcolors[0],
        borderWidth: 3,

      },
      {
        label: 'Value',
        data: valueSum,
        backgroundColor: backgroundcolors[1],
        borderColor: backgroundcolors[1],
        borderWidth: 3,

      },
      {
        label: 'Seniority',
        data: senioritySum,
        backgroundColor: backgroundcolors[2],
        borderColor: backgroundcolors[2],
        borderWidth: 3,

      },
      {
        label: 'Opportunity cost',
        data: oppCostSum,
        backgroundColor: backgroundcolors[3],
        borderColor: backgroundcolors[3],
        borderWidth: 3,

      },
    ];
    setChartData({ labels: seasons, datasets });
  };

  useEffect(() => {
    loadData(props.data);
  }, [props.data]);

  return (
    <div className="chart-section" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
      <div style={{ flex: '1 1 100%' }}>
        <Line data={chartData} options={chartOptions} width={600} height={500} ref={chartRef} />
      </div>
    </div>
  );
};

export default PlayerMarketValueAssessment;
