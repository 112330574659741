import { createAuth0Client } from '@auth0/auth0-spa-js';

class Auth0Service {
  auth0Client = null;
  
  async init() {
    const CLIENT_ID = process.env.REACT_APP_AUTH_CLIENT_ID;
    const DOMAIN = process.env.REACT_APP_AUTH_DOMAIN;
    const SERVER = process.env.REACT_APP_SERVER;
    this.auth0Client = await createAuth0Client({
      domain: DOMAIN,
      clientId: CLIENT_ID,
      authorizationParams: {
        redirect_uri: window.location.origin + '/assistant-manager/clubsummary',
        audience: SERVER
      }
    });
  }

  async getToken() {
    if (!this.auth0Client) {
      await this.init();
    }
    return this.auth0Client.getTokenSilently();
  }

  async getUser() {
    if(!this.auth0Client) {
      await this.init();
    }
    return this.auth0Client.getUser()
  }
}

export const auth0Service = new Auth0Service();