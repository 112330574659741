import React from 'react';
import { observer } from "mobx-react-lite";
import { Autocomplete, TextField, styled, CircularProgress } from '@mui/material';
import { impersonationStore } from '../State/ImpersonationStore';

const CustomAutocomplete = styled(Autocomplete)(({ theme }) => ({
  '& .MuiAutocomplete-root': { 
    fontSize: '20px',
    fontFamily: 'Montserrat, sans-serif', 
  },
  '& .MuiAutocomplete-inputRoot': { 
    fontSize: '20px', 
    fontFamily: 'Montserrat, sans-serif',
  },
  '& .MuiAutocomplete-listbox': {
    fontSize: '20px',
    fontFamily: 'Montserrat, sans-serif',
  },
}));

const Impersonation = observer(() => {

  const filterOptions = (options, state) => {
    const inputValue = state.inputValue.toLowerCase();
    return options.filter(option => 
      option.OfficialName.toLowerCase().includes(inputValue) ||
      option.DivisionName.toLowerCase().includes(inputValue)
    );
  };

  if (impersonationStore.isLoading) {
    return <CircularProgress />;
  }

  if (impersonationStore.error) {
    return <div>{impersonationStore.error}</div>;
  }

  return (
    <>
      <CustomAutocomplete
        id="impersonation"
        options={impersonationStore.dropdownOptions}
        disableClearable
        groupBy={(option) => option.DivisionName}
        getOptionLabel={(option) => option.OfficialName}
        onChange={impersonationStore.handleChange}
        value={impersonationStore.selectedOption}
        filterOptions={filterOptions}
        sx={{ width: 300 }}
        renderInput={(params) => (
          <TextField 
            {...params} 
            label="Club" 
            sx={{ 
              '& .MuiInputLabel-root': { fontSize: '24px', fontFamily: 'Montserrat, sans-serif' }, 
              '& .MuiInputBase-input': { fontSize: '24px', fontFamily: 'Montserrat, sans-serif' } 
            }} 
          />
        )}
        renderGroup={(params) => (
          <li key={params.key}>
            <div>{params.group}</div>
            <ul>{params.children}</ul>
          </li>
        )}
      />
    </>
   
  );
});

export default Impersonation;
