import { useLocation } from 'react-router-dom';

const useEmailVerificationCheck = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const error = queryParams.get('error');
  const errorDescription = queryParams.get('error_description');

  const isEmailVerificationError =
    error === 'access_denied' &&
    errorDescription === 'Please verify your email before continuing.';

  return isEmailVerificationError;
};

export default useEmailVerificationCheck;