import React from 'react';
import { Element } from 'react-scroll';
import '../Style/style.css';
import '../Style/am_style.css';

function Contact() {
    return (
      <Element name="contact" className="contact" id="contact">
        <div className='contact-container'>
          <div className='contact-list'>
            {/* New Open Positions Section */}
            <div className='contact-item'>
              <h3>Open Positions</h3>
              <p>We are always looking for talented individuals to join our team. Check out our open positions below:</p>
              <a href="https://goalunit.notion.site/Exjobb-Praktik-f61007a5892e4c71b33f36487b9979b2?pvs=74" className="contact-jobboard-link" target="_blank" rel="noopener noreferrer">
                Exjobb / Praktik - Stockholm
              </a>
            </div>
            <div className='contact-item'>
              <h3>Do you want to join <br />us or hire us?</h3>
              <p>We are always looking to get in contact with club<br /> representatives, skilled developers, and football fanatics. <br /> Don't hesitate to get in touch!</p>
              <ul>
                <li><span role="img" aria-label="phone">📞</span> +46 708 333 136</li>
                <li><span role="img" aria-label="email">✉️</span> info@goalunit.com</li>
                <li><span role="img" aria-label="web">🌐</span> www.goalunit.com</li>
              </ul>
            </div>
          </div>
        </div>
      </Element>
    );
}

export default Contact;
