import React, { useState, useEffect, useRef } from 'react';
import { Line, Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import { Chart, registerables } from 'chart.js';
import datalabels from 'chartjs-plugin-datalabels';
import { exportPdfStore } from '../State/ExportPdfStore';
import { transferKpiComparsionDescription } from '../Util/ChartDescriptions';
import '../Style/am_style.css';
import './Datapage.css'

Chart.register(...registerables);

const TransferKPIComparison = (props) => {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: []
  });
  const chartRef = useRef(null);

  const chartOptions = {
    responsive: true, 
    maintainAspectRatio: false, 
    interaction: {
        mode: 'nearest', 
        axis: 'x', 
        intersect: false
    }, 
    scales: 
    {
      y: {
        min: 0 
      }
    },
    plugins: {
      legend: {
        position: 'top',
        labels: {
          font: {
            size: 16 // Adjust this value to change the font size
          }
        }
      },
      datalabels: {
        color: 'white',
        font: {
          weight: 'bold'
        },
        formatter: (value) => value !== null ? value : '',
        anchor: 'center'
      }
    },
    animation: {
      onComplete: function() {
        if (chartRef.current) {
          const base64Image = chartRef.current.toBase64Image();
          exportPdfStore.setTransferKPIComparisonData(base64Image);
        }
      }
    }
  }; 
  
  const [uniqueSeasons, setUniqueSeasons] = useState([]);


  useEffect(() => {
    const backgroundcolors = [
      getComputedStyle(document.documentElement).getPropertyValue('--element-color-3'),
      getComputedStyle(document.documentElement).getPropertyValue('--element-color-2')
    ].map(color => {
      const rgbaColor = color.replace(')', ', 0.5)').replace('rgb', 'rgba');
      return rgbaColor;
    });
    
    const loadData = (data) => {
      var sortedData = data.filter(item => item.SeasonName);
      sortedData.sort((a, b) => a.SeasonName.localeCompare(b.SeasonName));
  
      const processedData = sortedData.map(KPI => ({
          ...KPI,
          NewTeamTransferKPI: KPI.NewTeamTransferKPI === 0 ? null : KPI.NewTeamTransferKPI,
          NewLeagueAverageTransferKPI: KPI.NewLeagueAverageTransferKPI === 0 ? null : KPI.NewLeagueAverageTransferKPI
      }));
  
      let uniqueSeasons = [
        ...new Set(sortedData.map(item => item.SeasonName))
      ]; 
      setUniqueSeasons(uniqueSeasons);
  
      const labels = processedData.map(KPI => KPI.SeasonName);
      const datasets = [
        {
          label: 'Club Transfer KPI', 
          data: processedData.map(KPI => KPI.NewTeamTransferKPI.toFixed(2)), 
          borderColor: backgroundcolors[0], 
          backgroundColor: backgroundcolors[0],
        },  
        {
          label: 'League average Transfer KPI', 
          data: processedData.map(KPI => KPI.NewLeagueAverageTransferKPI.toFixed(2)), 
          borderColor: backgroundcolors[1], 
          backgroundColor: backgroundcolors[1],
        }
      ];
      setChartData({ labels, datasets }); 
    }; 
  
    loadData(props.data);
  }, [props.data]);

  return (
    <div className="chart-section">
      {uniqueSeasons.length > 1 ? (
        <div style={{ width: '65%', margin: 'auto' }}>
          <Line data={chartData} options={chartOptions} width={500} height={450} ref={chartRef} />
        </div>
      ) : (
        <div style={{ width: '40%', margin: 'auto' }}>
          <Bar data={chartData} options={chartOptions} width={500} height={450} ref={chartRef} plugins={[datalabels]}/>
        </div>
      )}
      <div className="description-section">
        <div className="description-container">   
          <span style={{ fontWeight: 'bold' }}>Description: </span> <br></br>
           {transferKpiComparsionDescription}.
        </div>
      </div>
    </div>
  );
};

export default TransferKPIComparison;


