import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Button, styled } from '@mui/material';
import { profileStore } from "../../State/ProfileStore";
import './SubscriptionConfirmation.css';


const CustomTypography = styled(Typography)(({ theme }) => ({
    fontFamily: 'Montserrat, sans-serif',
    color: 'white', // This will make the text white
}));

const CustomButton = styled(Button)(({ theme }) => ({
    fontFamily: 'Montserrat, sans-serif',
    marginTop: theme.spacing(3),
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    backgroundColor: '#1A5F7A',
    '&:hover': {
        backgroundColor: '#23374C',
    },
}));

const SubscriptionConfirmation = () => {

    const navigate = useNavigate();

    const handleButtonClick = () => {
        profileStore.setCurrentTabIndex(0);
        navigate('/assistant-manager/profile');
      };

    return (
        <div className='component-wrapper'>
            <div className='subscription-confirmation'>
                <CustomTypography variant="h4" gutterBottom sx={{ mb: 5, fontWeight: 'bold' }}>
                    Subscription Successfully Updated!            
                </CustomTypography>
                <CustomTypography variant="h6" gutterBottom sx={{ mb: 5 }}>
                    We're excited to have you on board. Your subscription has been successfully activated.
                    <br/>
                    The new features will be unlocked for you shortly. Stay tuned for an enhanced experience!
                </CustomTypography>
                <CustomButton variant="contained" onClick={handleButtonClick}>
                    Return to Assistant Manager
                </CustomButton>
            </div>  
        </div>
    );
}

export default SubscriptionConfirmation;