import React from 'react';
import { Element } from 'react-scroll';
import lukasImg from '../Assets/Images/lukas.jpg';
import caesarImg from '../Assets/Images/caesar.jpg';
import carlImg from '../Assets/Images/carl.jpg';
import davidImg from '../Assets/Images/david.png'
import felixImg from '../Assets/Images/felix.png'
import nilsImg from '../Assets/Images/nils.png'
import tomasImg from '../Assets/Images/tomas.jpg'
import vilmerImg from '../Assets/Images/vilmer.png'

import '../Style/style.css';
import './teamStyle.css';

function Team() {
  const teamMembers = [
    { role: 'Founder', name: 'Caesar Gezelius', img: caesarImg },
    { role: 'Founder', name: 'Lukas Berglund', img: lukasImg },
    { role: 'Development', name: 'Carl Magnusson', img: carlImg },
    { role: 'Analysis', name: 'David Orre', img: davidImg },
    { role: 'Development', name: 'Felix Fungula', img: felixImg },
    { role: 'Development', name: 'Nils Forsgren', img: nilsImg },
    { role: 'Development', name: 'Tomas Weldetinsae', img: tomasImg },
    { role: 'Analysis', name: 'Vilmer Olin', img: vilmerImg }
  ];

  return (
    <Element name="team" className="team-section">
      <div className="team-container">
        <h1 className="team-header">Meet Our Team</h1>
        <div className="team-list">
          {teamMembers.map((member, index) => (
            <div key={index} className="team-item">
              <img src={member.img} alt={`${member.name}`} className="team-img" />
              <h3 className="team-role">{member.role}</h3>
              <p className="team-name">{member.name}</p>
            </div>
          ))}
        </div>
      </div>
    </Element>
  );
}

export default Team;
