import React, { useEffect, useState } from 'react';
import { observer } from "mobx-react-lite";
import { useAuth0 } from "@auth0/auth0-react";
import CircularProgress from '@mui/material/CircularProgress';
import UpdateIcon from '@mui/icons-material/Update';
import PerformanceComparison from './PerformanceComparison';

const Performance = observer(({ data }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [KPIvsFinancialData, setKPIvsFinancialData] = useState([]);
  const [KPIvsFinancialLeagueData, setKPIvsFinancialLeagueData] = useState([]);
  const [sessionClubId, setSessionClubId] = useState(null);


  useEffect(() => {
    const fetchSessionClubId = async () => {
      try {
        const REACT_APP_SERVER = process.env.REACT_APP_SERVER;
        const token = await getAccessTokenSilently();
        const header = { Authorization: `Bearer ${token}` };
        const response = await fetch(`${REACT_APP_SERVER}/assistant-manager/api/getsessionclubid`, { headers: header, credentials: 'include' });
        return await response.json();
      } catch (error) {
        console.error('Error fetching session club ID:', error);
        return null;
      }
    };

    setKPIvsFinancialData(data.KPIF || []);
    setKPIvsFinancialLeagueData(data.KPIFL || [])

    // Fetch sessionClubId once on component mount
    const loadClubId = async () => {
      const clubId = await fetchSessionClubId();
      setSessionClubId(clubId);
    };

    loadClubId();
  }, [data])

  return (
    <div className='chart-background'>
      <h2 className="subtitle"> Financial Overperformance </h2>
      <div className="chart-container">
        {KPIvsFinancialData.length > 0 ? (
          <div>
            <PerformanceComparison
              data={KPIvsFinancialData}
              leagueData={KPIvsFinancialLeagueData}
              sessionClubId={sessionClubId}
            />
            <div
              style={{
                textAlign: 'left',
                marginBottom: '10px',
                fontStyle: 'italic',
                color: '#666'
              }}
            >
              <UpdateIcon sx={{ mb: -0.4, fontSize: '20px' }} />  <span> Chart updates weekly</span>
            </div>
          </div>

        ) : (
          <CircularProgress />
        )}
      </div>
      <div className='separator '></div>
    </div>
  );
});

export default Performance;