import { makeAutoObservable, runInAction } from 'mobx';
import { makePersistable } from 'mobx-persist-store';

class ExportPdfStore {

    transferKPIData = "";
    playerMarketValueAssessmentData = "";
    marketValueComparisonData = "";
    marketValueAssessmentComparisonDataForGrowth = ""; 
    marketValueAssessmentComparisonDataForValue = ""; 
    marketValueAssessmentComparisonDataForSeniority = ""; 
    marketValueAssessmentComparisonDataForOppCost = ""; 
    playTimeDistributionData = "";
    powerrankData = "";
    transferKPIComparisonData = "";
    transferKPIRiskData = "";

    isWindowMaximizedStatus = "";
    clearStatusTimeOut = null;

    constructor() {
        makeAutoObservable(this);
        makePersistable(this, 
            { 
                name: 'PdfStore', 
                properties: [
                    'transferKPIRiskData',
                    'transferKPIData',
                    'playerMarketValueAssessmentData',
                    'marketValueComparisonData',
                    'playTimeDistributionData',
                    'powerrankData',
                    'transferKPIComparisonData',
                    'marketValueAssessmentComparisonDataForGrowth',
                    'marketValueAssessmentComparisonDataForValue',
                    'marketValueAssessmentComparisonDataForSeniority',
                    'marketValueAssessmentComparisonDataForOppCost'
                ],
                storage: window.localStorage
            }
        );
    }

    setTransferKPIData(data) {
        runInAction(() => {
            this.transferKPIData = data;
        });
    }

    setPlayerMarketValueAssessmentData(data) {
        runInAction(() => {
            this.playerMarketValueAssessmentData = data;
        });
    }

    setMarketValueComparisonData(data) {
        runInAction(() => {
            this.marketValueComparisonData = data;
        });
    }

    setMarketValueAssessmentComparisonData(data, id) {
        if(id === "Growth") {
            runInAction(() => {
                this.marketValueAssessmentComparisonDataForGrowth = data;
            });
        }

        if(id === "Value") {
            runInAction(() => {
                this.marketValueAssessmentComparisonDataForValue = data;
            });
        }

        if(id === "Seniority") {
            runInAction(() => {
                this.marketValueAssessmentComparisonDataForSeniority = data;
            });
        }

        if(id === "Opportunity Cost") {
            runInAction(() => {
                this.marketValueAssessmentComparisonDataForOppCost = data;
            });
        }
    }

    setPlayTimeDistributionData(data) {
        runInAction(() => {
            this.playTimeDistributionData = data;
        });
    }

    setPowerrankData(data) {
        runInAction(() => {
            this.powerrankData = data;
        });
    }

    setTransferKPIComparisonData(data) {
        runInAction(() => {
            this.transferKPIComparisonData = data;
        });

    }

    setTransferKPIRiskData(data) {
        runInAction(() => {
            this.transferKPIRiskData = data;
        });

    }

    setIsWindowMaximized(status) {
        this.isWindowMaximizedStatus = status;

        if (this.clearStatusTimeOut) {
            clearTimeout(this.clearStatusTimeOut);
        }

        this.clearStatusTimeout = setTimeout(() => {
            runInAction(() => {
                this.isWindowMaximizedStatus = '';
                this.clearStatusTimeout = null;
            });
        }, 7000);
    }
}

export const exportPdfStore = new ExportPdfStore();