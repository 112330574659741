import React from 'react';
import { Element } from 'react-scroll';
import logo from '../Logos/Goalunit Logo - BrightVertical.svg';
import '../Style/style.css';
import '../Style/am_style.css';
function Home() {
    return (
      <Element name="home" className="home" id="home">
        <div className="home-container">
          <div className="home-list">
            <div className="home-item">
            <img src={logo} alt="Goalunit" className="home-logo" />
            </div>
            <div className="home-item">
              <h1>Elevating Football Strategy</h1>
            </div>
          </div>
        </div>
      </Element>
    );
  }

export default Home;