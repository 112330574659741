const waterMarkPlugin = {
    id: 'waterMarkPlugin',
    afterDatasetsDraw: ((chart, args, plugins) => {
        const img = new Image(); 
        img.src='/Black-logo.svg'; 

        const { ctx, chartArea: {top,  left,  width, height}} = chart; 

        ctx.save(); 
        ctx.globalAlpha = 0.1;

        const centerX = left + width / 2 - (0.15*width); 
        const centerY = top + height / 2 - (0.15*width);

        ctx.drawImage(img, centerX, centerY, 0.3*width, 0.3*width);
        ctx.restore(); 
    }) 
}

export default waterMarkPlugin; 
