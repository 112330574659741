// SquadPage.js
import React from 'react'; 
import { observer } from "mobx-react-lite";
import { CircularProgress } from '@mui/material';
import { squadStore } from '../../State/SquadStore';
import SquadContainer from '../SquadContainer';
import './SquadPageOverview.css';

const SquadPage = observer(() => {
    const dataIsEmpty = squadStore.data.length === 1;
    return (
        <div className='squad'>
            {(!squadStore.isLoading) ? (
                dataIsEmpty ? (
                    <div className="empty-squad-container">
                        <h2>No squad found</h2>
                    </div>
                ) : (
                    <SquadContainer data={squadStore.data} />
                )
            ) : (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <CircularProgress />
                </div>
            )}
        </div>
    );
});

export default SquadPage;
