import React from 'react';
import { Element } from 'react-scroll';
import '../Style/style.css';
import '../Style/am_style.css';

function Services() {
  return (
    <Element name="services" className="services" id="services">
      <div className="service-container">
        <h1>Services</h1>

        <div className="service-list">
          <div className="service-item">
          <h3>Strategy & Operations</h3>
            <p className="service-text">
              Optimizing your sporting strategy and follow-up <br className='regular-break'></br> through data-driven KPI:s and improved decision material, creating transparency and improving the control of your results on and off the pitch.
            </p>
          </div>
          <div className='border'></div>
          <div className="service-item">
            <h3>Assistant Manager</h3>
            <p className="service-text">Our cutting edge platform automates your strategic follow-up and enables you to allocate time to make decisions instead of compiling presentations.</p>
          </div>
          <div className='border'></div>
          <div className="service-item">
            <h3>Financing</h3>
            <p className="service-text">Goalunit provides external financing to football clubs with mechanisms leveraging long-term decisions.</p>
          </div>
        </div>
      </div>
    </Element>
  );
}

export default Services;