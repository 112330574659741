import React, { useState }  from "react";
import { Page, Text, Document, StyleSheet, View, Image, pdf, Font} from "@react-pdf/renderer";
import { Button, styled, Tooltip } from '@mui/material';
import { exportPdfStore } from '../State/ExportPdfStore';
import {
  marketValueComparisonDescription,
  powerRankDescrption,
  transferKpiRiskDescription,
  transferKpiDescription,
  playTimeDistributionDescription,
  transferKpiComparsionDescription,
  marketValueAssessmentComparsionGrowthDescription,
  marketValueAssessmentComparisonValueDescription,
  marketValueAssessmentComparisonSeniroityDescription,
  marketValueAssessmentComparisonOppCostDescription
} from "../Util/ChartDescriptions";
import logo from "../Logos/Goalunit Logo - Navy Horisontal.png";
// Import Montserrat fonts from local files
import montserratRegular from '../Style/Montserrat-Regular.ttf';
import montserratBold from '../Style/Montserrat-Bold.ttf';

// Chart descriptions in single line
const marketValueComparisonDescriptionSingleLine = marketValueComparisonDescription.replace(/\n\s+/g, ' ').trim();
const powerRankDescrptionSingleLine = powerRankDescrption.replace(/\n\s+/g, ' ').trim();
const transferKpiDescriptionSingleLine = transferKpiDescription.replace(/\n\s+/g, ' ').trim();
const transferKpiRiskDescriptionSingleLine = transferKpiRiskDescription.replace(/\n\s+/g, ' ').trim();
const playTimeDistributionDescriptionSingleLine = playTimeDistributionDescription.replace(/\n\s+/g, ' ').trim();
const transferKpiComparsionDescriptionSingleLine = transferKpiComparsionDescription.replace(/\n\s+/g, ' ').trim();
const marketValueAssessmentComparsionGrowthDescriptionSingleLine = marketValueAssessmentComparsionGrowthDescription.replace(/\n\s+/g, ' ').trim();
const marketValueAssessmentComparisonValueDescriptionSingleLine = marketValueAssessmentComparisonValueDescription.replace(/\n\s+/g, ' ').trim();
const marketValueAssessmentComparisonSeniroityDescriptionSingleLine = marketValueAssessmentComparisonSeniroityDescription.replace(/\n\s+/g, ' ').trim();
const marketValueAssessmentComparisonOppCostDescriptionSingleLine = marketValueAssessmentComparisonOppCostDescription.replace(/\n\s+/g, ' ').trim();

// Tooltip title
const toolTipTitle = "Make sure to always maximize your browser window and refresh the page before generating the PDF report"

// Register Montserrat fonts
Font.register({
  family: 'Montserrat',
  fonts: [
    { src: montserratRegular, fontWeight: 'normal' },
    { src: montserratBold, fontWeight: 'bold' }
  ]
});

// Define styles for the PDF
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
    padding: 20,
  },
  header: {
    position: 'absolute',
    top: 10,
    left: 0,
    right: 0,
    paddingHorizontal: 20,
    paddingBottom: 5,
    borderBottomWidth: 0.5,
    borderBottomColor: '#000',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    zIndex: 1, // Ensure header is on top
  },
  logo: {
    width: 80,
    height: 20,
  },
  contentContainer: {
    marginTop: 50, // Adjust this value to ensure content starts below the header
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
    fontSize: 20,
    textAlign: 'center',
    marginBottom: 10,
  },
  imageContainer: {
    width: '90%',
    height: '80%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    objectFit: 'contain',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  description: {
    fontFamily: 'Montserrat',
    fontWeight: 'normal',
    fontSize: 12,
    textAlign: 'center',
    marginTop: 10,
  },
  bold: {
    fontFamily: 'Montserrat',
    fontSize: 14,
    fontWeight: 'bold',
    textAlign: 'center',
  },
});

// A reusable component for each page in the PDF
const GraphPage = ({ title, imageData, descriptionParts }) => (
  <Page size="A4" style={styles.page}>
    <View style={styles.header} fixed>
      <Image style={styles.logo} src={logo} />
    </View>
    <View style={styles.contentContainer}>
      <Text style={styles.title}>{title}</Text>
      <View style={styles.imageContainer}>
        <Image style={styles.image} src={imageData} />
      </View>
      <View>
        {descriptionParts.map((line, index) => (
          <View key={index} style={styles.description}>
            <Text style={styles.bold}>{line.label}</Text>
            <Text>{line.text}</Text>
          </View>
        ))}
      </View>
    </View>
  </Page>
);


// The complete PDF document structure
const PDFDocument = () => {
  return (
    <Document>
      {exportPdfStore.powerrankData && (
        <GraphPage
        title="Power Rank"
        imageData={exportPdfStore.powerrankData}
        descriptionParts={[
          {
            label: "",
            text: powerRankDescrptionSingleLine,
          },
        ]}
      />
      )}
      { exportPdfStore.transferKPIData && (<GraphPage
        title="Transfer KPI"
        imageData={exportPdfStore.transferKPIData}
        descriptionParts={[
          {
            label: "",
            text: transferKpiDescriptionSingleLine,
          },
        ]}
      />
      )}
      {exportPdfStore.transferKPIRiskData && (
        <GraphPage
          title="Transfer KPI Risk"
          imageData={exportPdfStore.transferKPIRiskData}
          descriptionParts={[
            {
              label: "",
              text: transferKpiRiskDescriptionSingleLine,
            },
          ]}
        />
      )}
      { exportPdfStore.playerMarketValueAssessmentData && (
        <GraphPage
        title="Market value share per category"
        imageData={exportPdfStore.playerMarketValueAssessmentData}
        descriptionParts={[
          {
            label: "",
            text: "The graph shows the share of market value per player category within the club's squad.",
          },
        ]}
      />
      )}
      { exportPdfStore.marketValueComparisonData && (
        <GraphPage
        title="Average Squad value"
        imageData={exportPdfStore.marketValueComparisonData}
        descriptionParts={[
          {
            label: "",
            text: marketValueComparisonDescriptionSingleLine,
          },
        ]}
      />
      )}
      { exportPdfStore.playTimeDistributionData && (
        <GraphPage
        title="Age Distribution"
        imageData={exportPdfStore.playTimeDistributionData}
        descriptionParts={[
          {
            label: "",
            text: playTimeDistributionDescriptionSingleLine,
          },
        ]}
      />
      )}
      { exportPdfStore.transferKPIComparisonData && (
        <GraphPage
        title="Transfer KPI Comparison"
        imageData={exportPdfStore.transferKPIComparisonData}
        descriptionParts={[
          {
            label: "",
            text: transferKpiComparsionDescriptionSingleLine,
          },
        ]}
      />
      )}
      { exportPdfStore.marketValueComparisonData && (
        <GraphPage
        title="Market Value Assessment Comparison"
        imageData={exportPdfStore.marketValueComparisonData}
        descriptionParts={[
          {
            label: "Growth",
            text: "Under 25, less than 50% play time.",
          },
          {
            label: "Value",
            text: "Under 25, more than 50% play time.",
          },
          {
            label: "Seniority",
            text: "26 and older, more than 50% play time.",
          },
          {
            label: "Opportunity Cost",
            text: "26 and older, less than 50% play time.",
          }
        ]}
      />
      )}
      { exportPdfStore.marketValueAssessmentComparisonDataForGrowth && (
        <GraphPage
        title="Market Value Assessment Comparison - Growth"
        imageData={exportPdfStore.marketValueAssessmentComparisonDataForGrowth}
        descriptionParts={[
          {
            label: "",
            text: marketValueAssessmentComparsionGrowthDescriptionSingleLine,
          },
        ]}
      />
      )}
      { exportPdfStore.marketValueAssessmentComparisonDataForValue && (
        <GraphPage
        title="Market Value Assessment Comparison - Value"
        imageData={exportPdfStore.marketValueAssessmentComparisonDataForValue}
        descriptionParts={[
          {
            label: "",
            text: marketValueAssessmentComparisonValueDescriptionSingleLine,
          },
        ]}
      />
      )}
      { exportPdfStore.marketValueAssessmentComparisonDataForSeniority && (
        <GraphPage
        title="Market Value Assessment Comparison - Seniority"
        imageData={exportPdfStore.marketValueAssessmentComparisonDataForSeniority}
        descriptionParts={[
          {
            label: "",
            text: marketValueAssessmentComparisonSeniroityDescriptionSingleLine,
          },
        ]}
      />
      )}
      { exportPdfStore.marketValueAssessmentComparisonDataForOppCost && (
        <GraphPage
        title="Market Value Assessment Comparison - Opportunity Cost"
        imageData={exportPdfStore.marketValueAssessmentComparisonDataForOppCost}
        descriptionParts={[
          {
            label: "",
            text: marketValueAssessmentComparisonOppCostDescriptionSingleLine,
          },
        ]}
      />
      )}
    </Document>
  );
};

const PDFDocumentGenerator = async () => {
  return await pdf(<PDFDocument />).toBlob();
};

// Define the custom button with conditional styling
const CustomButton = styled(Button)(({ theme, isLoading }) => ({
  fontFamily: 'Montserrat, sans-serif',
  marginTop: theme.spacing(3),
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  backgroundColor: isLoading ? 'red' : '#1A5F7A',
  '&:hover': {
    backgroundColor: isLoading ? 'darkred' : '#23374C',
  },
}));

// Main component
const ExportPDF = () => {
 
  const [isLoading, setIsLoading] = useState(false);

  const handleExportPDF = async () => {
    setIsLoading(true); // Start loading state
    // Simulate a delay of 4 seconds to ensure all data is ready
    await new Promise(resolve => setTimeout(resolve, 4000));
  
    const pdfBlob = await PDFDocumentGenerator();
    const url = URL.createObjectURL(pdfBlob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'Report.pdf';
    link.click();
    setIsLoading(false); // Stop loading state
  };

  return (
    <div>
      <Tooltip title={toolTipTitle}>
      <CustomButton variant="contained" onClick={handleExportPDF} isLoading={isLoading}>
        {isLoading ? 'Exporting PDF ...' : 'Export Report to PDF'}
      </CustomButton>
      </Tooltip>
    </div>

  );
};

export default ExportPDF;