import React, { useState, useRef, useEffect } from 'react';
import { Delete } from '@mui/icons-material';
import { styled } from '@mui/system';
import ToggleButton from '@mui/material/ToggleButton';
import CloseIcon from '@mui/icons-material/Close';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import './SideBar.css';

const CustomButton = styled(ToggleButton)(({ theme }) => ({
    fontFamily: 'Montserrat, sans-serif',
    marginTop: theme.spacing(3),
    paddingTop: theme.spacing(2), 
    paddingBottom: theme.spacing(2), 
    backgroundColor: '#1A5F7A',
    '&:hover': {
      backgroundColor: '#23374C',
    },
    color: '#f8f8f8',
  }));
  

const generateColorFromString = (str) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = '#';
    for (let i = 0; i < 3; i++) {
        const value = (hash >> (i * 8)) & 0xFF;
        color += ('00' + value.toString(16)).substr(-2);
    }
    return color;
};

const Sidebar = ({ availableClubs, selectedClubs, setSelectedClubs }) => {
  const [isOpen, setIsOpen] = useState(false);
  const sidebarRef = useRef(null);
  const buttonRef = useRef(null);  
  const [searchQuery, setSearchQuery] = useState('');

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const handleClubSelection = (club) => {
    const isSelected = selectedClubs.includes(club);
    if (isSelected) {
      setSelectedClubs(selectedClubs.filter(selectedClub => selectedClub !== club));
    } else {
      setSelectedClubs([...selectedClubs, club]);
    }
  };

  const handleClickOutside = (event) => {
    if ( sidebarRef.current && !sidebarRef.current.contains(event.target) && 
    buttonRef.current && !buttonRef.current.contains(event.target)){
        setIsOpen(false);
    }
};

  useEffect(() => {
    if (isOpen) {
        document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
        document.removeEventListener('mousedown', handleClickOutside);
    };
}, [isOpen]);

  return (
    <>
      <CustomButton ref={buttonRef} onClick={toggleSidebar} className="open-sidebar-btn">
      <CompareArrowsIcon className="compare-icon" /> Compare Club
                </CustomButton>

      <div  ref={sidebarRef}  className={`sidebar ${isOpen ? 'open' : ''}`}>     
        <div className="sidebar-header">
          <h2>Compare Club</h2>
          <button className="close-btn" onClick={toggleSidebar}>
          <CloseIcon />
          </button>
        </div>
        <input 
          type="text" 
          placeholder="Search Clubs..." 
          value={searchQuery} 
          onChange={(e) => setSearchQuery(e.target.value)} 
          className="club-search-input" 
        />

        <div className="sidebar-content">
          <div className="checkbox-list">
            <div className="scrollable-checkbox-list">
              {availableClubs
              .filter((club) => club.toLowerCase().includes(searchQuery.toLowerCase()))
              .sort((a, b) => a.localeCompare(b))
              .map((club, index) => (
                <div 
                  key={index} 
                  className="club-checkbox-container" 
                  onClick={() => handleClubSelection(club)} 
                >
                     <div className="icon-wrapper">
                              {selectedClubs.includes(club) && (
                                  
                      <CheckCircleIcon className="check-circle-icon" style={{fontSize: 'medium'}}/> )}
                    </div>
                  <input 
                    className='check-box'
                    type="checkbox"
                    id={`club-${index}`}
                    name="club"
                    value={club}
                    checked={selectedClubs.includes(club)}
                    readOnly
                  />
                    <span>{club}</span>
                </div>
              ))}
            </div>
          </div>
          <div className='separator'></div>
          <div className="selected-clubs-box">
            <div className="selected-club-list">
            {selectedClubs.map((club, index) => (
                <div
                  key={index}
                  className="selected-club-item"
                  onClick={() => setSelectedClubs(selectedClubs.filter(selectedClub => selectedClub !== club))}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                >
                    <div className="icon-wrapper">
                    <Delete className="delete-icon" style={{fontSize: 'medium'}}/>
                    </div>
                    <span>{club}</span>
                  <div
                    className="color-dot"
                    style={{ 
                      backgroundColor: generateColorFromString(club),
                     
                    }}
                  ></div> 
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
