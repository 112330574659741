import React, { useEffect, useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import CustomTable from "../Components/Table"

const BillingHistory = () => {
    const [ tableData, setTableData ] = useState([]);
    const { getAccessTokenSilently } = useAuth0();

   const [tableColumns] = useState([
        { field: 'PlanName', headerName: 'Plan',},
        { field: 'status', headerName: 'Status'}, 
        { field: 'total', headerName: 'Total'}, 
        { field: 'due_date', headerName:'Due'},
        { field: 'paid_at', headerName:'Paid'},
        { field: 'id', headerName: 'Invoice', isDownloadInvoice: true},
    ]);

    useEffect(() => {
        const fetchBillingHistory = async () => {
    
            try {
                const REACT_APP_SERVER = process.env.REACT_APP_SERVER;
                const token = await getAccessTokenSilently();
                const header = { Authorization: `Bearer ${token}` };
                const response = await fetch(`${REACT_APP_SERVER}/assistant-manager/api/billinghistory`, { headers: header, credentials: 'include' });
                const invoiceData = await response.json();

                const convertDateFields = (field, multiplier = 1000) => {
                    if (field !== 0) {
                        const date = new Date(field * multiplier);
                        const day = String(date.getDate()).padStart(2, '0');
                        const month = String(date.getMonth() + 1).padStart(2, '0'); 
                        const year = date.getFullYear();
                        return `${day}/${month}/${year}`;
                    } else {
                        return null;
                    }
                };
                const convertCurrency = ( amountField, currencyCode ) => {
                    if (amountField !== 0) {
                        const currencySymbol = currencyCode === 'EUR' ? '€' : '';
                        return `${currencySymbol} ${(amountField / 100).toFixed(2)}`;
                    } else {
                        return null;
                    }
                } 

                invoiceData.forEach(invoice => {
                    
                    invoice.invoice.amount_paid = convertCurrency(invoice.invoice.amount_paid, invoice.invoice.currency_code);
                    invoice.invoice.total = convertCurrency(invoice.invoice.total, invoice.invoice.currency_code);
                    invoice.invoice.credits_applied = convertCurrency(invoice.invoice.credits_applied, invoice.invoice.currency_code);

                    invoice.invoice.date = convertDateFields(invoice.invoice.date);
                    invoice.invoice.due_date = convertDateFields(invoice.invoice.due_date);
                    invoice.invoice.paid_at = convertDateFields(invoice.invoice.paid_at);
                })
                
                let flattenedData = invoiceData.map(row => {
                    const PlanName = row.invoice.line_items[0].description
                    return {
                        PlanName, 
                        ...row.invoice
                    }
                })
                setTableData(flattenedData)
               
            } catch (error) {
                console.log("Something went wrong", error);
            }
        };
        fetchBillingHistory();
    }, []); 

    return (
        <div style={{ width: '70%', overflowX: 'auto' }}>
            <CustomTable TableData={tableData} TableColumns={tableColumns} />
        </div>
    );
}
export default BillingHistory;