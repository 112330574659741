import React, { useState } from 'react'; 
import { useAuth0 } from '@auth0/auth0-react';
import { AiFillEdit } from "react-icons/ai";
import { observer } from "mobx-react-lite";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import FormControl from '@mui/material/FormControl';
import {styled } from '@mui/material/styles'
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs";
import FloatingButton from '../Components/FloatingButton/FloatingButton';
import { squadStore } from '../State/SquadStore';
import isAdministrator from '../Authorization/isAdministrator';
import { positionsStore } from '../State/PositionsStore';
import { countriesStore } from '../State/CountriesStore';
import profile from "../Logos/empty_profile.png";


const SquadContainer = observer(({ data }) => {
    const [, setSelectedPosition] = useState(false);
    const [, setSelectedCountry] = useState(false);

    const [, setSelectedNationality] = useState(false);
    const { user } = useAuth0();
    const [selectedPlayer, setSelectedPlayer] = useState(null);
    const [selectedEditPlayer, setSelectedEditPlayer] = useState(null);
    const [selectedPositionId, setselectedPositionId] = useState('');
    const [selectedCountryId, setselectedCountryId] = useState('');

    const [selectedHeight, setselectedHeight] = useState('')
    const [selectedWeight, setselectedWeight] = useState('')
    const [selectedFoot, setselectedFoot] = useState('');
    const [selectedNationalityId, setselectedNationalityId] = useState('');

    const [selectedBirthDate, setselectedBirthDate] = useState(dayjs());
    const [ContractExpiration, setContractExpiration] = useState(dayjs());

    const handleClick = (player) => {
        setSelectedPlayer(player);
    };

    const handleEditClick = (player) => {
        setSelectedEditPlayer(player);
    };

    const handleClose = () => {
        setSelectedPlayer(null);
    };

    const handleEditClose = () => {
        setSelectedEditPlayer(null);
    };


    const handlePositionChange = (event) => {
        setSelectedPosition(true);
        setselectedPositionId(event.target.value);
    };

    const handleCountryChange = (event) => {
        setSelectedCountry(true);
        setselectedCountryId(event.target.value);
    };

    const generateSuggestions = (min, max) => {
        let heights = [];
        for (let i = min; i <= max; i++) {
            heights.push(i);
        }
        return heights;
    };

    const heightSuggestions = generateSuggestions(155, 250);
    const weightSuggestions = generateSuggestions(50, 150);

    const handleFootChange = (event, value) => {
        if (value != null) {
            setselectedFoot(value);
        }

    }

    const handleNationalityChange = (event) => {
        setSelectedNationality(true);
        setselectedNationalityId(event.target.value);
    };

    const handleDateChange = (date) => {
        if (date && date.isValid()) {
            setselectedBirthDate(date);
        } else {
            console.error("Invalid date:", date);
        }
    };

    const handleContractChange = (date) => {
        if (date && date.isValid()) {
            setContractExpiration(date);
        } else {
            console.error("Invalid date:", date);
        }
    };

    const handleHeightChange = (event, value) => {
        if (value !== null) {
            setselectedHeight(value);
        }
    };

    const handleWeightChange = (event, value) => {
        if (value !== null) {
            setselectedWeight(value);
        }
    };

    const handleSubmit = async () => {
        if (selectedEditPlayer) {
            await squadStore.updatePlayerPosition(selectedEditPlayer.PlayerId, selectedPositionId, selectedCountryId,
                selectedBirthDate.format('YYYY-MM-DD'), ContractExpiration.format('YYYY-MM-DD'), selectedFoot, selectedHeight, selectedWeight, selectedNationalityId, selectedEditPlayer.ClubId);
            setSelectedEditPlayer(null);
        }
    };

    const footOptions = ["left", "right", "both"];

    const players = data.map(player => ({
        ClubName: player.ClubName,
        FirstName: player.FirstName,
        LastName: player.LastName,
        DateOfBirth: player.DateOfBirth,
        Foot: player.Foot,
        Height: player.Height,
        Weight: player.Weight,
        Country: player.Country,
        Nationality: player.Nationality,
        Position: player.Position,
        TransferKPI: player.TransferKPI,
        ContractExp: player.ContractExp,
        PlayerId: player.PlayerId,
        ClubId: player.ClubId,
    }));

    const positions = positionsStore.data.map(position => ({
        PositionId: position.PositionId,
        PositionName: position.PositionName,
        PositionGroupName: position.PositionGroupName,
    }));
    const countries = countriesStore.data.map(country => ({
        AreaName: country.AreaName,
        AreaId: country.AreaId
    }));

    const CustomButton = styled(Button)({
        backgroundColor: '#23374C',
        marginTop: '30px',
        marginLeft: '50px',
        width: 200,
        minHeight: 50,
        '&:hover': {
            backgroundColor: '#798DA8', // You can add hover styles if needed
        },
    });

    return (
        <div className='squad-container'>
            <div className={`squad-list ${isAdministrator(user?.email) ? 'admin' : 'non-admin'}`}>
                <div className='squad-item'>
                    <div className={`header-info-row ${isAdministrator(user?.email) ? 'admin' : 'non-admin'}`}>
                        <div className={`header-name-cell ${isAdministrator(user?.email) ? 'admin' : 'non-admin'}`}><h2>Name</h2></div>
                        <div className={`header-pos-cell ${isAdministrator(user?.email) ? 'admin' : 'non-admin'}`}><h2>Position</h2></div>
                        <div className={`header-foot-cell ${isAdministrator(user?.email) ? 'admin' : 'non-admin'}`}><h2>Foot</h2></div>
                        <div className={`header-date-cell ${isAdministrator(user?.email) ? 'admin' : 'non-admin'}`}><h2>Date of Birth</h2></div>
                        <div className={`header-transfer-cell ${isAdministrator(user?.email) ? 'admin' : 'non-admin'}`}><h2>Transfer KPI</h2></div>
                        <div className={`header-country-cell ${isAdministrator(user?.email) ? 'admin' : 'non-admin'}`}><h2>Country</h2></div>
                        <div className='fill-out'></div >
                    </div>
                </div>
                {players.map((player, index) => (
                    <div key={index} className="squad-item">

                        <div className='table-info-row'>
                            <div className={`table-name-cell ${isAdministrator(user?.email) ? 'admin' : 'non-admin'}`}>
                                <button
                                    className={`player-box ${selectedPlayer === player ? 'active' : ''}`}
                                    onClick={() => handleClick(player)}
                                >
                                    <h3>{player.FirstName} {player.LastName}</h3>
                                </button>
                            </div>
                            <div className={`table-pos-cell ${isAdministrator(user?.email) ? 'admin' : 'non-admin'}`}><p>{player.Position}</p></div>
                            <div className={`table-foot-cell ${isAdministrator(user?.email) ? 'admin' : 'non-admin'}`}><p>{player.Foot}</p></div>
                            <div className={`table-date-cell ${isAdministrator(user?.email) ? 'admin' : 'non-admin'}`}><p>{player.DateOfBirth}</p></div>
                            <div className={`table-transfer-cell ${isAdministrator(user?.email) ? 'admin' : 'non-admin'}`}><p>{player.TransferKPI}</p></div>
                            <div className={`table-country-cell ${isAdministrator(user?.email) ? 'admin' : 'non-admin'}`}>{player.Country}</div>
                            {isAdministrator(user?.email) ? (<button className={`player-edit-box ${selectedEditPlayer === player ? 'active' : ''}`}
                                onClick={() => handleEditClick(player)}><AiFillEdit /></button>) : null}
                        </div>
                    </div>
                ))}
            </div>
            {selectedPlayer && (
                <div className="selected-player">
                    <div className="player-details">
                        <button className='close-button' onClick={handleClose}>
                            < CloseIcon />
                        </button>
                        <h1>{selectedPlayer.FirstName} {selectedPlayer.LastName}</h1>
                        <div className="info-pieces">
                            <div className='info-piece'>
                                <img src={profile} className='profile-image' alt='profile' />
                            </div>
                            <div className='info-piece'>
                                <p>Position: {selectedPlayer.Position}</p>
                                <p>Foot: {selectedPlayer.Foot}</p>
                                <p>Date of Birth: {selectedPlayer.DateOfBirth}</p>
                                <p>Country: {selectedPlayer.Country}</p>
                                <p>Nationality: {selectedPlayer.Nationality}</p>
                                <p>Height: {selectedPlayer.Height}</p>
                                <p>Weight: {selectedPlayer.Weight}</p>
                                <p>Contract Until: {selectedPlayer.ContractExp} </p>
                                <p>TransferKPI: {selectedPlayer.TransferKPI} </p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {selectedEditPlayer && (
                <div id="pinkbox" className="selected-edit-player">
                    <div className="player-edit-details">
                        <button className='close-button' onClick={handleEditClose}>
                            < CloseIcon />
                        </button>
                        <h1>{selectedEditPlayer.FirstName} {selectedEditPlayer.LastName} </h1>

                        {isAdministrator(user?.email) ? (
                            <div className='edit-pieces'>
                                <div className='edit-piece'>
                                    <FormControl>
                                        <Autocomplete
                                            id="position-select"
                                            options={positions}
                                            getOptionLabel={(option) => option.PositionName}
                                            value={positions.find((position) => position.PositionId === selectedPositionId) || null}
                                            onChange={(event, selectedValue) => {
                                                handlePositionChange({
                                                    target: { value: selectedValue ? selectedValue.PositionId : '' }
                                                });
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={`Position: ${selectedEditPlayer.Position}`}
                                                />
                                            )}
                                            groupBy={(option) => option.PositionGroupName}
                                        />
                                    </FormControl>
                                </div>

                                <div className='edit-piece'>
                                    <FormControl>
                                        <Autocomplete
                                            id="nation-select"
                                            options={countries}
                                            getOptionLabel={(option) => option.AreaName}
                                            value={countries.find((country) => country.AreaId === selectedCountryId) || null}
                                            onChange={(event, selectedValue) => {
                                                handleCountryChange({
                                                    target: { value: selectedValue ? selectedValue.AreaId : '' }
                                                });
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={`Country: ${selectedEditPlayer.Country}`}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </div>

                                <div className='edit-piece'>
                                    <FormControl>
                                        <Autocomplete
                                            id="nation-select"
                                            options={countries}
                                            getOptionLabel={(option) => option.AreaName}
                                            value={countries.find((country) => country.AreaId === selectedNationalityId) || null}
                                            onChange={(event, selectedValue) => {
                                                handleNationalityChange({
                                                    target: { value: selectedValue ? selectedValue.AreaId : '' }
                                                });
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={`Nationality: ${selectedEditPlayer.Nationality}`}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </div>

                                <div className='edit-piece'>
                                    <FormControl>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                label="Change date of birth"
                                                value={selectedBirthDate}
                                                onChange={handleDateChange}
                                                renderInput={(params) => <TextField {...params} />}
                                                maxDate={dayjs()}
                                            />
                                        </LocalizationProvider>
                                    </FormControl>
                                </div>

                                <div className='edit-piece'>
                                    <FormControl>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                label="Change contract expiration"
                                                value={ContractExpiration}
                                                onChange={handleContractChange}
                                                renderInput={(params) => <TextField {...params} />}
                                                minDate={dayjs()}
                                            />
                                        </LocalizationProvider>
                                    </FormControl>
                                </div>

                                <div className='edit-piece'>
                                    <FormControl>
                                        <Autocomplete
                                            options={footOptions}
                                            getOptionLabel={(option) => `${option}`}
                                            renderInput={(params) => <TextField {...params} label={`Foot: ${selectedEditPlayer.Foot}`} variant="outlined" />}
                                            value={selectedFoot}
                                            onChange={handleFootChange}
                                            disableClearable
                                            freeSolo

                                        />
                                    </FormControl>
                                </div>

                                <div className='edit-piece'>
                                    <FormControl>
                                        <Autocomplete
                                            options={heightSuggestions}
                                            getOptionLabel={(option) => `${option}`}
                                            renderInput={(params) => <TextField {...params} label={`Height (cm): ${selectedEditPlayer.Height}`} variant="outlined" />}
                                            value={selectedHeight}
                                            onChange={handleHeightChange}
                                            disableClearable
                                            freeSolo
                                        />
                                    </FormControl>
                                </div>

                                <div className='edit-piece'>
                                    <FormControl>
                                        <Autocomplete
                                            options={weightSuggestions}
                                            getOptionLabel={(option) => `${option}`}
                                            renderInput={(params) => <TextField {...params} label={`Weight (kg): ${selectedEditPlayer.Weight}`} variant="outlined" />}
                                            value={selectedWeight}
                                            onChange={handleWeightChange}
                                            disableClearable
                                            freeSolo

                                        />
                                    </FormControl>
                                </div>

                                <div className='edit-piece'>
                                    <FormControl>
                                        <CustomButton className='update-button' variant="contained" onClick={handleSubmit}>
                                            <h3>Update</h3>
                                        </CustomButton>
                                    </FormControl>
                                </div>
                            </div>
                        ) : <div className='edit-pieces'></div>}
                    </div>
                </div>
            )}
            <FloatingButton />
        </div>
    );
});

export default SquadContainer;

