import React, { useState, useEffect } from 'react';
import { observer } from "mobx-react-lite";
import CircularProgress from '@mui/material/CircularProgress';
import UpdateIcon from '@mui/icons-material/Update';
import TransferKPI from './Transferkpi';
import PlayTimeDistribution from './Playtimedistribution';
import TransferKPIComparison from './Transferkpicomparison';
import MarketValueComparison from './Marketvaluecomparison';
import MarketValueAssessmentComparison from './Marketvalueassessmentcomparison';
import PlayerMarketValueAssessment from './Playermarketvalueassessment';
import Powerrank from './Powerrank';
import FloatingButton from '../Components/FloatingButton/FloatingButton';
import TransferKPIRisk from './Transferkpirisk';
import ExportPDF from '../Components/ExportPDF';
import './Datapage.css';
import './Dashboard.css'


const Datapage = observer(({ data }) => {

  const [transferKPIData, setTransferKPIData] = useState([])
  const [playerMarketValueAssessmentData, setPlayerMarketValueAssessmentData] = useState([])
  const [marketValueComparisonData, setMarketValueComparisonData] = useState([])
  const [marketValueAssessmentComparisonData, setMarketValueAssessmentComparisonData] = useState([])
  const [playTimeDistributionData, setPlayTimeDistributionData] = useState([])
  const [transferKPIComparisonData, setTransferKPIComparisonData] = useState([])
  const [powerrankData, setPowerrankData] = useState([])
  const [transferKPIRiskData, setTransferKPIRiskData] = useState([])


  useEffect(() => {
    setTransferKPIComparisonData(data.TK || []);
    setTransferKPIData(data.KPI || []);
    setPlayerMarketValueAssessmentData(data.PMA || []);
    setMarketValueComparisonData(data.MCV || []);
    setMarketValueAssessmentComparisonData(data.MCAC || []);
    setPlayTimeDistributionData(data.PTD || []);
    setPowerrankData(data.PR || []);
    setTransferKPIRiskData(data.TKR || []);
  }, [data]);

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'flex-end', flex: 1, marginTop: '10px', marginRight: '50px' }}>
        <ExportPDF />
      </div>
      <div className='chart-background'>
        <h2 className="subtitle">Transfer KPI</h2>
        <div className="chart-container">
          {transferKPIData.length > 0 ? (
            <div>
              <TransferKPI data={transferKPIData} />
              <div
                style={{
                  textAlign: 'left',
                  marginBottom: '10px',
                  fontStyle: 'italic',
                  color: '#666'
                }}
              >
                <UpdateIcon sx={{ mb: -0.4, fontSize: '20px' }} />  <span> Chart updates after each league game </span>
              </div>
            </div>
          ) : (
            <CircularProgress />
          )}
        </div>
      </div>

      <div className='chart-background' style={{ marginTop: '50px' }}>
        <div className="chart-container">
          {transferKPIComparisonData.length > 0 ? (
            <div>
              <TransferKPIComparison data={transferKPIComparisonData} />
              <div
                style={{
                  textAlign: 'left',
                  marginBottom: '10px',
                  fontStyle: 'italic',
                  color: '#666'
                }}
              >
                <UpdateIcon sx={{ mb: -0.4, fontSize: '20px' }} />  <span> Chart updates weekly</span>
              </div>
            </div>
          ) : (
            <CircularProgress />
          )}
        </div>
      </div>

      <div className='separator '></div>
      <div className='chart-background'>
        <h2 className="subtitle">Power Rank</h2>
        <div className="chart-container">
          {powerrankData.length > 0 ? (
            <div>
              <Powerrank data={powerrankData} />
              <div
                style={{
                  textAlign: 'left',
                  marginBottom: '10px',
                  fontStyle: 'italic',
                  color: '#666'
                }}
              >
                <UpdateIcon sx={{ mb: -0.4, fontSize: '20px' }} />  <span> Chart updates weekly</span>
              </div>
            </div>
          ) : (
            <CircularProgress />
          )}
        </div>
      </div>
      <div className='separator '></div>

      <div className='chart-background'>
        <h2 className="subtitle">Market value share per category</h2>
        <div className="chart-container">

          {playerMarketValueAssessmentData.length > 0 ? (
            <div>
              <PlayerMarketValueAssessment data={playerMarketValueAssessmentData} />
              <div
                style={{
                  textAlign: 'left',
                  marginBottom: '10px',
                  fontStyle: 'italic',
                  color: '#666'
                }}
              >
                <UpdateIcon sx={{ mb: -0.4, fontSize: '20px' }} />  <span> Chart updates weekly</span>
              </div>
            </div>
          ) : (
            <CircularProgress />
          )}
        </div>
      </div>
      <div style={{ marginTop: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div className='chart-container' style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          {marketValueAssessmentComparisonData.length > 0 ? (
            <MarketValueAssessmentComparison data={marketValueAssessmentComparisonData} />
          ) : (
            <CircularProgress />
          )}
        </div>
      </div>

      <div className='separator '></div>
      <div className='chart-background'>
        <h2 className="subtitle">Average Squad value</h2>
        <div className="chart-container">
          {marketValueComparisonData.length > 0 ? (
            <div>
              <MarketValueComparison data={marketValueComparisonData} />
              <div
                style={{
                  textAlign: 'left',
                  marginBottom: '10px',
                  fontStyle: 'italic',
                  color: '#666'
                }}
              >
                <UpdateIcon sx={{ mb: -0.4, fontSize: '20px' }} />  <span> Chart updates weekly</span>
              </div>
            </div>
          ) : (
            <CircularProgress />
          )}
        </div>
      </div>

      <div className='separator '></div>
      <div className='chart-background'>
        <h2 className="subtitle">Age Distribution</h2>
        <div className="chart-container">
          {playTimeDistributionData.length > 0 ? (
            <div>
              <PlayTimeDistribution data={playTimeDistributionData} />
              <div
                style={{
                  textAlign: 'left',
                  marginBottom: '10px',
                  fontStyle: 'italic',
                  color: '#666'
                }}
              >
                <UpdateIcon sx={{ mb: -0.4, fontSize: '20px' }} />  <span> Chart updates weekly</span>
              </div>
            </div>
          ) : (
            <CircularProgress />
          )}

        </div>
      </div>

      <div className='separator '></div>
      <div className='chart-background'>
        <h2 className="subtitle">Transfer KPI Risk</h2>
        <div className="chart-container">
          {transferKPIRiskData.length > 0 ? (
            <div>
              <TransferKPIRisk data={transferKPIRiskData} comparisonData={transferKPIComparisonData} />
              {transferKPIRiskData.some(item => item.PlayerName) && (
                <div
                  style={{
                    textAlign: 'left',
                    marginBottom: '10px',
                    fontStyle: 'italic',
                    color: '#666'
                  }}
                >
                  <UpdateIcon sx={{ mb: -0.4, fontSize: '20px' }} />  <span> Chart updates weekly</span>
                </div>
              )}
            </div>
          ) : (
            <CircularProgress />
          )}
        </div>
      </div>
      <div className='separator '></div>
      <FloatingButton />
    </div>
  );
});

export default Datapage;