export function getColorForValue(value) {
    const cappedValue = Math.max(0, Math.min(value, 3));
    const redToGreen = (120 * (cappedValue / 3));

    let light = 50

    if (redToGreen > 90) {
        light = light - 20 * (redToGreen / 120)
    } else if (redToGreen > 60) {
        light = light - 10 * (redToGreen / 120)
    } else if (redToGreen > 30) {
        light = light - 5 * (redToGreen / 120)
    }

    return `hsl(${redToGreen}, 100%, ${light}%)`;
}
