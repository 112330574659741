function isAdministrator(email) {

    if (typeof email === 'string') {
        if (email.split('@')[1] === process.env.REACT_APP_ADMINISTRATOR_EMAIL_DOMAIN) {
            return true 
        }
    }
  return false;
}

export default isAdministrator