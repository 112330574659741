import { makeAutoObservable, runInAction } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import { auth0Service } from '../Util/Auth0Client';

class CountriesStore {
    data = [];
    isLoading = false;
    error = null;
    countriesType = 'Countries';
    constructor() {
        makeAutoObservable(this);
        makePersistable(this, {
            name: 'CountriesStore',
            properties: ['data'],
            storage: window.localStorage
        });
    }
    fetchData = async () => {
        this.setLoading(true);
        this.setError(null);

        try {
            const REACT_APP_SERVER = process.env.REACT_APP_SERVER;
            const token = await auth0Service.getToken();
            const header = { Authorization: `Bearer ${token}` };
            const response = await fetch(`${REACT_APP_SERVER}/assistant-manager/api/countries`, { headers: header, credentials: 'include' }); 
            const countriessData = await response.json();
            runInAction(() => {
                this.setData(countriessData);
            });
        } catch (error) {
            runInAction(() => {
                this.setError(error.message);
            });
        } finally {
            runInAction(() => {
                this.setLoading(false);
            });
        }
    }

    setData(newData) {
        this.data = newData;
    }

    setLoading(loading) {
        this.isLoading = loading;
    }

    setError(error) {
        this.error = error;
    }


    getCountriesType = () => {
        return this.countriesType;
    }
}

export const countriesStore = new CountriesStore();