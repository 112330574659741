import React from 'react';
import { getColorForValue } from '../Datapage/utilFunctions';

function ColorGradient() {
  const colors = [];
  const labels = [];

  const step = 0.1;
  const numSteps = Math.round(3 / step);

  for (let i = 0; i <= numSteps; i++) {
    const value = i * step;
    colors.push(getColorForValue(value));

    if (Math.round(value * 10) % 5 === 0) {
      labels.push(value.toFixed(1));
    } else {
      labels.push("");
    }
  }

  return (
    <div style={{ display: 'flex', alignItems: "center", height: '20px', marginTop: '25px', marginBottom: '20px' }}>
      {colors.map((color, index) => (
        <div
          key={index}
          style={{
            width: `${100 / colors.length}%`,
            height: '100%',
            backgroundColor: color,
            position: 'relative',
          }}
        >
          <div
            style={{
              position: 'absolute',
              bottom: '30px',
              left: '50%',
              transform: 'translateX(-50%)',
              fontSize: '20px',
              textAlign: 'center',
              fontFamily: 'Montserrat, sans-serif'
            }}
          >
            {labels[index]}
          </div>
        </div>
      ))}
    </div>
  );
}

export default ColorGradient;