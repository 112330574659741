import React, { useState, useEffect, useRef } from 'react';
import { observer } from "mobx-react-lite";
import { Bubble } from 'react-chartjs-2';
import 'chart.js/auto';
import { Chart, registerables } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import datalabels from 'chartjs-plugin-datalabels';
import { Select, FormControl, InputLabel, MenuItem, styled } from '@mui/material';
import { seasonStore } from '../State/SeasonStore';
import { exportPdfStore } from '../State/ExportPdfStore';
import { getColorForValue } from './utilFunctions';
import ColorGradient from '../Components/ColorGradient';
import CustomSlider from '../Components/CustomSlider';
import { transferKpiDescription } from '../Util/ChartDescriptions';
import './Datapage.css';


Chart.register(...registerables, annotationPlugin);

const CustomSelect = styled(Select)(({ theme }) => ({
  width: '250px',
  '& .MuiInputBase-input': {
    position: 'relative',
    fontSize: '24px',
    fontFamily: 'Montserrat, sans-serif',

    textAlign: 'center',  
  },
  '& .MuiSelect-select': {
    display: 'flex',
    alignItems: 'center',  // Vertically center the selected value
    justifyContent: 'center',  // Horizontally center the selected value
  },
}));

const SeasonDropDown = observer(() => (
  <FormControl sx={{ width: '30%', maxWidth: 200, marginBottom:  '10px'}}>
      <InputLabel id="season-select-label">Season</InputLabel>
      <CustomSelect
        labelId="season-select-label"
        value={seasonStore.selectedSeason?.SeasonId || ''}
        onChange={seasonStore.handleChange}
        label="Season"
      >
        {seasonStore.data.map((season) => (
          <MenuItem key={season.SeasonId} value={season.SeasonId}>
            {season.SeasonName}
          </MenuItem>
        ))}
      </CustomSelect>
  </FormControl>
)); 

const TransferKPI = (props) => {
  const [ chartData, setChartData ] = useState({ datasets: [] });
  const [ sliderValue, setSliderValue ] = useState(null);
  const [ max, setMaxSlidervalue ] = useState(null);
  const [ originalData, setOriginalData ] = useState([]);
  const [ customMarks, setMarks ] = useState([]);
  const [ isOnlyOneMatch, setIsOnlyOneMatch ] = useState(false);
  const [ lastSeasonMatch, setLastSeasonMatch ] = useState(null);

  const chartRef = useRef(null);

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: 1,
    scales: {
      y: {
        type: 'linear',
        min: 0,
        max: 110,
        ticks: {
          stepSize: 10,
          callback: function (value) {
            if (value % 10 === 0 && value <= 100 && value >= 0) {
              return value;
            }
          },
        },
        title: {
          display: true,
          text: "Play time (%)"
        },
        beginAtZero: false
      },
      x: {
        type: 'linear',
        min: 16,
        max: 38, // The value shown on the chart should be "36+""
        ticks: {
          stepSize: 2,
          callback: function (value, index, values) {
            if (value === 36) {
              return "36+"
            } else if (value === 16) {
              return "16-"
            }
            else if (value > 36 || value < 16) {
              return
            } else {
              return value
            }
          }
        },
        title: {
          display: true,
          text: "Age"
        }
      }
    },
    plugins: {
      datalabels: {
        formatter: function (value) {
          return `${value.firstName[0]}. ${value.lastName}`;
        },
        align: 'end',
        anchor: 'end',
        offset: 2,
        padding: 0,
        clamp: true,
        clip: false,
        font: {
          size: 12,
          family: 'Montserrat, sans-serif',
        },
        color: '#333',
      },
      legend: {
        display: false
      },
      annotation: {
        annotations: {
          line1: {
            type: 'line',
            yMin: 0,
            yMax: 100,
            xMin: 25,
            xMax: 25,
            borderColor: 'grey',
            borderWidth: 1,
            borderDash: [5, 5]
          },
          line2: {
            type: 'line',
            yMin: 50,
            yMax: 50,
            xMin: 0,
            xMax: 36,
            borderColor: 'grey',
            borderWidth: 1,
            borderDash: [5, 5]
          }
        }
      },
      tooltip: {
        callbacks: {
          labelFont: {
            fontSize: 15,
            fontFamily: 'Montserrat, sans-serif',
          },
          label: function (context) {
            return [
              `${context.raw.fullName}`,
              `KPI: ${context.raw.TransferKPI}`,
              `Age: ${context.raw.originalAge}`,
              `Play time: ${context.raw.y}%`,
              `Contract expiration: ${context.raw.contractExpiration}`
            ];
          }
        }
      }
    },
    animation: {
      onComplete: function () {
        if (chartRef.current) {
          const base64Image = chartRef.current.toBase64Image();
          exportPdfStore.setTransferKPIData(base64Image);
        }
      }
    }
  };

  const loadData = (jsonData) => {
    try {

      const data = jsonData.map(player => ({
        
        x: player.Age > 36 ? 36 : (player.Age < 16 ? 16 : player.Age),
        y: player.PartOfPlayingTime > 100 ? 100 : player.PartOfPlayingTime,
        r: (player.NewTransferKpi > 3 ? 3 : player.NewTransferKpi) * 5 + 5,
        originalAge: player.Age,
        firstName: player.FirstName, 
        lastName: player.LastName, 
        firstName: player.FirstName,
        lastName: player.LastName,
        fullName: player.PlayerName,
        matchesPlayedByTeam: player.MatchesPlayedByTeam,
        backgroundColor: getColorForValue(player.NewTransferKpi),
        contractExpiration: player.ContractExpiration,
        TransferKPI: player.NewTransferKpi.toFixed(2),

      }));

      const matchesPlayed = Math.max(...data.map(d => d.matchesPlayedByTeam));
      const sortedUniqueMatchesPlayed = [...new Set(data.map(item => item.matchesPlayedByTeam))].sort((a, b) => a - b);
      setSliderValue(matchesPlayed)
      setMaxSlidervalue(matchesPlayed)
      setOriginalData(data);
      filterData(matchesPlayed, data);

      
      const marks = (() => {
        if(matchesPlayed !== sortedUniqueMatchesPlayed.length) {
          setIsOnlyOneMatch(true)
          setLastSeasonMatch(matchesPlayed)
          return [
            {
              value: sortedUniqueMatchesPlayed[sortedUniqueMatchesPlayed.length - 1],
              label: sortedUniqueMatchesPlayed[sortedUniqueMatchesPlayed.length - 1].toString()
            }
          ]
        } else {
          setIsOnlyOneMatch(false)
          setLastSeasonMatch(matchesPlayed)
          return Array.from({ length: matchesPlayed }, (_, i) => {
            if ((i + 1) % 5 === 0 || i === 0 || i === matchesPlayed - 1) {
              return {
                value: i + 1,
                label: (i + 1).toString(),
              };
            }
            return { value: i + 1 };
          }).filter(mark => mark.label !== '');
        }
      })
      
      setMarks(marks);

    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

 
  const filterData = (value, data = originalData) => {
    const filteredData = [...data].filter(d => d.matchesPlayedByTeam === value);

    // Sort by age and playtime, and then by radius in descending order
    filteredData.sort((a, b) => {
        if (a.x !== b.x) {
          return a.x - b.x;
        } else if (a.y !== b.y) {
          return a.y - b.y;
        } else {
          // Sort by radius (r) so that smaller bubbles appear on top
          return b.r - a.r;
        }
    });
  
    // Update the chart data
    setChartData({
      datasets: [{
        data: filteredData,
        backgroundColor: filteredData.map(d => d.backgroundColor),
      }],
    });
  };


  useEffect(() => {
    if (props.data && props.data.length > 0) {
      loadData(props.data);
    } else {
    }
  }, [props.data]);


  useEffect(() => {
    if (originalData.length) {
      filterData(sliderValue);
    }
  }, [sliderValue, originalData]);

  if (sliderValue === null || max === null) {
    return <div>Loading...</div>;
  }

  return (
    <div className="chart-section">
      <div style={{ flex: '1 1 60%' }}>
        <SeasonDropDown />
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '10px' }}>
          <span style={{ fontWeight: 'bold', marginRight: '10px', marginBottom: '20px' }}>Games</span>
          <CustomSlider
            aria-label="Matches"
            value={sliderValue}
            onChange={(event, value) => {
              setSliderValue(value);
            }}
            valueLabelDisplay="auto"
            step={1}
            marks={customMarks}
            min={1}
            max={isOnlyOneMatch ? lastSeasonMatch : max}
            disabled={isOnlyOneMatch}
          />
        </div>
        <div>
          <Bubble data={chartData} options={chartOptions} plugins={[datalabels]} ref={chartRef} />
        </div>
        <div style={{ marginTop: '50px' }}><ColorGradient /></div>
      </div>
      <div className="description-section">
        <div className="description-container">
          <span style={{ fontWeight: 'bold' }} >Description: </span>
          <br></br>
          {transferKpiDescription}
          <br></br>
          <br></br>
          <span style={{ fontWeight: 'bold', fontSize: "16px" }} >Note: </span>
          <br></br>
          <span style={{ fontSize: "16px" }}> Remember to use the gameweek slider above the chart, to see your development during the season! </span>
          <br></br>
          <br></br>
          <span style={{ fontSize: "16px" }}> Players on loan at the club will have 0 Transfer KPI and players away on loan will not be visible. </span>
        </div>
      </div>
    </div>
  );
};

export default TransferKPI;