import React, { useState, useEffect, useRef } from 'react';
import { observer } from "mobx-react-lite";
import CircularProgress from '@mui/material/CircularProgress';
import UpdateIcon from '@mui/icons-material/Update';
import ClubKPIs from './ClubKPIs';
import TransferKPI from './Transferkpi';
import Powerrank from './Powerrank';
import MarketValueComparison from './Marketvaluecomparison';
import PlayTimeDistribution from './Playtimedistribution';
import TransferKPIComparison from './Transferkpicomparison';
import PlayerMarketValueAssessment from './Playermarketvalueassessment';
import MarketValueAssessmentComparison from './Marketvalueassessmentcomparison';
import TransferKPIRisk from './Transferkpirisk';
import FloatingButton from '../Components/FloatingButton/FloatingButton';
import './Dashboard.css'


const Dashboard = observer(({ data }) => {

    const [cardsKPIData, setCardsKPIData] = useState([])
    const [transferKPIData, setTransferKPIData] = useState([])
    const [selectedCard, setSelectedCard] = useState('transferKPI');
    const [powerrankData, setPowerrankData] = useState([]);
    const [playerMarketValueAssessmentData, setPlayerMarketValueAssessmentData] = useState([]);
    const [marketValueComparisonData, setMarketValueComparisonData] = useState([]);
    const [marketValueAssessmentComparisonData, setMarketValueAssessmentComparisonData] = useState([]);
    const [playTimeDistributionData, setPlayTimeDistributionData] = useState([]);
    const [transferKPIComparisonData, setTransferKPIComparisonData] = useState([]);
    const [transferKPIRiskData, setTransferKPIRiskData] = useState([]);

    const cardRef = useRef(null);

    useEffect(() => {
        setCardsKPIData(data);
        setTransferKPIComparisonData(data.TK || []);
        setTransferKPIData(data.KPI || []);
        setPlayerMarketValueAssessmentData(data.PMA || []);
        setMarketValueComparisonData(data.MCV || []);
        setMarketValueAssessmentComparisonData(data.MCAC || []);
        setPlayTimeDistributionData(data.PTD || []);
        setPowerrankData(data.PR || []);
        setTransferKPIRiskData(data.TKR || []);
    }, [data]);

    const handleCardClick = (card) => {
        setSelectedCard(card);
        if (cardRef.current) {
            cardRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };


    return (
        <div>
            <div>
                <ClubKPIs
                    cardsKPIData={cardsKPIData}
                    selectedCard={selectedCard}
                    onCardClick={handleCardClick}
                />
            </div>

            <div className="separator"></div>

            <div className="chart-background">
                <h2 className="subtitle"
                 ref={cardRef}
               >
                    {selectedCard === 'transferKPI' && "Transfer KPI"}
                    {selectedCard === 'squadValue' && "Average Squad Value"}
                    {selectedCard === 'powerRank' && "Power Rank"}
                    {selectedCard === 'playTimeDistribution' && "Age Distribution"}
                    {selectedCard === 'GVSO' && "Market value share per category"}
                    {selectedCard === 'transferKPIRiskLevel' && "Transfer KPI Risk"}
                </h2>
                {selectedCard === 'transferKPI' && (
                    transferKPIData.length > 0 ? (
                        <>
                            <div className="chart-container">
                                <div>
                                    <TransferKPI data={transferKPIData} />
                                    <div
                                        style={{
                                            textAlign: 'left',
                                            marginBottom: '10px',
                                            fontStyle: 'italic',
                                            color: '#666'
                                        }}
                                    >
                                        <UpdateIcon sx={{ mb: -0.4, fontSize: '20px' }} />  <span> Chart updates after each league game</span>
                                    </div>
                                </div>
                            </div>
                            <div className="separator"></div>
                            <div className="chart-container">
                                <div>
                                    <TransferKPIComparison data={transferKPIComparisonData} />
                                    <div
                                        style={{
                                            textAlign: 'left',
                                            marginBottom: '10px',
                                            fontStyle: 'italic',
                                            color: '#666'
                                        }}
                                    >
                                        <UpdateIcon sx={{ mb: -0.4, fontSize: '20px' }} />  <span> Chart updates weekly</span>
                                    </div>
                                </div>
                            </div>
                            <div className="separator"></div>
                        </>

                    ) : (
                        <CircularProgress />
                    )
                )}
                {selectedCard === 'squadValue' && (
                    marketValueComparisonData.length > 0 ? (
                        <>
                            <div className="chart-container">
                                <div>
                                    <MarketValueComparison data={marketValueComparisonData} />
                                    <div
                                        style={{
                                            textAlign: 'left',
                                            marginBottom: '10px',
                                            fontStyle: 'italic',
                                            color: '#666'
                                        }}
                                    >
                                        <UpdateIcon sx={{ mb: -0.4, fontSize: '20px' }} />  <span> Chart updates weekly</span>
                                    </div>
                                </div>
                            </div>
                            <div className="separator"></div>
                        </>

                    ) : (
                        <CircularProgress />
                    )
                )}
                {selectedCard === 'powerRank' && (
                    powerrankData.length > 0 ? (
                        <>
                            <div className="chart-container">
                                <div>
                                    <Powerrank data={powerrankData} />
                                    <div
                                        style={{
                                            textAlign: 'left',
                                            marginBottom: '10px',
                                            fontStyle: 'italic',
                                            color: '#666'
                                        }}
                                    >
                                        <UpdateIcon sx={{ mb: -0.4, fontSize: '20px' }} />  <span> Chart updates weekly</span>
                                    </div>
                                </div>
                            </div>
                            <div className="separator"></div>
                        </>
                    ) : (
                        <CircularProgress />
                    )
                )}
                {selectedCard === 'playTimeDistribution' && (
                    playTimeDistributionData.length > 0 ? (
                        <>
                            <div className="chart-container">
                                <div>
                                    <PlayTimeDistribution data={playTimeDistributionData} />
                                    <div
                                        style={{
                                            textAlign: 'left',
                                            marginBottom: '10px',
                                            fontStyle: 'italic',
                                            color: '#666'
                                        }}
                                    >
                                        <UpdateIcon sx={{ mb: -0.4, fontSize: '20px' }} />  <span> Chart updates weekly</span>
                                    </div>
                                </div>
                            </div>
                            <div className="separator"></div>
                        </>
                    ) : (
                        <CircularProgress />
                    )
                )}
                {selectedCard === 'GVSO' && (
                    playerMarketValueAssessmentData.length > 0 ? (
                        <>
                            <div className="chart-container">
                                <div>
                                <PlayerMarketValueAssessment data={playerMarketValueAssessmentData} />
                                <div
                                        style={{
                                            textAlign: 'left',
                                            marginBottom: '10px',
                                            fontStyle: 'italic',
                                            color: '#666'
                                        }}
                                    >
                                        <UpdateIcon sx={{ mb: -0.4, fontSize: '20px' }} />  <span> Chart updates weekly</span>
                                    </div>
                                </div>
                            </div>
                            <div className="separator"></div>
                            <div className="chart-container">
                                <MarketValueAssessmentComparison data={marketValueAssessmentComparisonData} />
                            </div>
                            <div className="separator"></div>
                        </>
                    ) : (
                        <CircularProgress />
                    )
                )}
                {selectedCard === 'transferKPIRiskLevel' && (
                    transferKPIRiskData.length > 0 ? (
                        <>
                            <div className="chart-container">
                                <div>
                                <TransferKPIRisk data={transferKPIRiskData} comparisonData={transferKPIComparisonData} />
                                {transferKPIRiskData.some(item => item.PlayerName) && (
                                    <div
                                        style={{
                                            textAlign: 'left',
                                            marginBottom: '10px',
                                            fontStyle: 'italic',
                                            color: '#666'
                                        }}
                                    >
                                        <UpdateIcon sx={{ mb: -0.4, fontSize: '20px' }} />  <span> Chart updates weekly</span>
                                    </div>
                                )}
                                </div>
                            </div>
                            <div className="separator"></div>
                        </>

                    ) : (
                        <CircularProgress />
                    )
                )}

            </div>
            <FloatingButton />
        </div>
    );
});

export default Dashboard;