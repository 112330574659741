import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import { Chart, registerables } from 'chart.js';
import App from './App';
import AuthProviderWithHistory from './Authorization/AuthProviderWithHistory';
import customWatermarkPlugin from './Components/Watermark'; 


Chart.register(...registerables, customWatermarkPlugin);

Chart.defaults.font.family = 'Montserrat, sans-serif'; 
Chart.defaults.font.size = 24;


const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <BrowserRouter>
      <AuthProviderWithHistory>
        <App />
      </AuthProviderWithHistory>
  </BrowserRouter>
);

reportWebVitals();
