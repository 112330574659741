import React,{ useState, useEffect } from 'react'; 
import { Link as ScrollLink } from 'react-scroll';
import { NavLink, useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import Impersonation from '../Impersonation';
import logo from "../../Logos/Goalunit Logo - Navy Horisontal.svg";
import './Navbar.css'; 


function Navbar() {
    const { user, logout, isAuthenticated } = useAuth0();
    const location = useLocation();
    const scrolldown_nav_path = location.pathname.split("/")[1]

    const [offset, setOffset] = useState(getOffset());

    useEffect(() => {
        const handleResize = () => setOffset(getOffset());
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    function getOffset() {
        const width = window.innerWidth;
        if (width > 2048) return -80;
        if (width > 768) return -60;
        return -60;
    }

    const handleLogout = () => {
        localStorage.removeItem('ClubSummaryStore');
        localStorage.removeItem('ImpersonationStore');
        localStorage.removeItem('ProfileStore');
        localStorage.removeItem('PdfStore');
        localStorage.removeItem('SquadPageStore');
        localStorage.removeItem('UserEntitlementStore');
        localStorage.removeItem('PositionsStore');
        localStorage.removeItem('CountriesStore');
        localStorage.removeItem('SeasonStore')
        logout();
      };

    return (
        <nav className="navbar">
            <NavLink to="/">
                <img src={logo} alt="goalunitlogo" className='nav-logo'/>
            </NavLink>
            { location.pathname === "/" &&  (<ScrollLink to="home" smooth={true} duration={500} hashSpy={true} offset={offset} className={"scroll-link"}>Home</ScrollLink>) }
            { location.pathname === "/" &&  (<ScrollLink to="services" smooth={true} duration={500} hashSpy={true} offset={offset} className={"scroll-link"}>Services</ScrollLink>) }
            { location.pathname === "/" && isAuthenticated ? (
            <NavLink to="/assistant-manager/clubsummary" className="nav-link">Assistant Manager</NavLink>
            ) : (
            location.pathname === "/" && <ScrollLink to="am" smooth={true} duration={500} hashSpy={true} offset={-80} className={"scroll-link"}>Assistant Manager </ScrollLink>
            )}
            { location.pathname === "/" &&  (<ScrollLink to="about" smooth={true} duration={500} hashSpy={true} offset={offset} className={"scroll-link"}>About</ScrollLink>) }
            { location.pathname === "/" &&  (<ScrollLink to="contact" smooth={true} duration={500} hashSpy={true} offset={offset} className={"scroll-link"}>Contact</ScrollLink>) }

            {isAuthenticated && scrolldown_nav_path === "assistant-manager" && (
            <>
                <Impersonation className="nav-link" />
                <NavLink to="/assistant-manager/squadpage" className="nav-link">Squad</NavLink>
                <NavLink to="/assistant-manager/clubsummary" className="nav-link">Club summary</NavLink>
                <NavLink to="/assistant-manager/profile" className="nav-link">Profile</NavLink>
                <NavLink to="/logout" className="nav-link" onClick={handleLogout}>Logout</NavLink>
            </>
        )}
        </nav>
    )
}

export default Navbar;